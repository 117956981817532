import React from "react";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ImageTransform from "./Common/ggfx-client/module/components/image-transform";

const GETOFFICE=  gql`
   query GetOfficeQuery ($Phone: String!){   
    ourOffices(where:{Office_Phone_contains:$Phone}){
        id
        Office_Phone
        Office_Tile_Image {
            url
        }
        imagetransforms
    }
  }
`;
const GetOffice = (props) => {

    const { loading, error, data } = useQuery(GETOFFICE, {variables: { Phone: props.phone }});

    if (loading) return <p className="d-none">Loading ...</p>;

    return (
        <>
                {data && data.ourOffices.map((office,key) => {
                    let processedImages = JSON.stringify({});
                    if (office?.imagetransforms?.Office_Tile_Image_Transforms) {
                        processedImages = office.imagetransforms.Office_Tile_Image_Transforms;
                    }	
                    return<>
                    <picture>
                    <ImageTransform imagesources={office.Office_Tile_Image.url} renderer="srcSet" imagename="our-offices.Office_Tile_Image.smallimg" attr={{ alt: office.Office_Tile_Image.alternativeText}} imagetransformresult={processedImages} id={office.id}/>     
                    </picture>
                    </>                   
                })}
        </>
    )
}
export default GetOffice;

