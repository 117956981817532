import React, {useState} from "react";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Link } from "gatsby";
import Lightbox from 'react-image-lightbox';

const GETIMAGECOUNT=  gql`
   query GetImageQuery ($ID: String!){   
    properties(where:{id:$ID}){
        id
        images
    }
  }
`;
const GetImageCount = (props) => {

    const [photoIndex, setPhotoIndex] = useState(0);

    const [isOpen, setIsOpen] = useState(false);

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    const { loading, error, data } = useQuery(GETIMAGECOUNT, {variables: { ID: props.id }});

    if (loading) return <p className="d-none">Loading ...</p>;

    return (
        <>
        {data && data.properties.map((property,key) => {
        var lightImages2 = property.images && property.images.map(img => img.url);
        return<>
        <a href="javascript:;" onClick={(e) => openLighboxMobile(e, 0)} className="view-img">
            <i className="icon-view-img"></i>
               <span>{property.images && property.images.length}</span>
        </a>
        {isOpen && (
                            <Lightbox
                                mainSrc={lightImages2[photoIndex]}
                                nextSrc={lightImages2[(photoIndex + 1) % lightImages2.length]}
                                prevSrc={lightImages2[(photoIndex + lightImages2.length - 1) % lightImages2.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex((photoIndex + lightImages2.length - 1) % lightImages2.length)
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % lightImages2.length)
                                }
                            />
                        )}
        </>
        })}
        </>
    )
}
export default GetImageCount;

